export default [
    {
        path: "/",
        redirect: () => {
            return { name: "events" };
        },
        name: "admin",
        meta: { admin: true },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/admin/auth/LoginView.vue"),
        meta: { admin: true },
    },
    {
        path: "/signup",
        name: "signup",
        component: () => import("@/views/admin/auth/RegisterView.vue"),
        meta: { admin: true },
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("@/views/admin/auth/ResetPasswordView.vue"),
        meta: { admin: true },
    },
    {
        path: "/auth/action",
        name: "auth-action",
        component: () => import("@/views/admin/auth/AuthActionView.vue"),
        meta: { admin: true },
    },
    {
        path: "/verify-email",
        name: "verify-email",
        component: () => import("@/views/admin/auth/VerifyEmailView.vue"),
        meta: { admin: true },
    },
    {
        path: "/events",
        component: () => import("@/views/admin/BaseView.vue"),
        meta: { admin: true, requiresAuth: true },
        children: [
            {
                path: "",
                name: "events",
                component: () => import("@/views/admin/EventsView.vue"),
                meta: { admin: true },
            },
            {
                path: "create",
                name: "events-create",
                component: () => import("@/views/admin/CreateEventView.vue"),
                meta: { admin: true },
            },
            {
                path: ":eventSlug",
                name: "event",
                component: () => import("@/views/admin/EventView.vue"),
                meta: { admin: true },
            },
            {
                path: ":eventSlug/:pathMatch(.*)*",
                redirect: (to) => {
                    return {
                        name: "event",
                        params: { eventSlug: to.params.eventSlug },
                    };
                },
                meta: { admin: true },
            },
        ],
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/admin/ProfileView.vue"),
        meta: { admin: true, requiresAuth: true },
    },
    {
        path: "/profile/licenses/get",
        name: "get-license",
        component: () => import("@/views/admin/LicenseView.vue"),
        meta: { admin: true, requiresAuth: true },
    },
    {
        path: "/profile/licenses/callback",
        name: "license-callback",
        component: () => import("@/views/admin/LicenseView.vue"),
        meta: { admin: true, requiresAuth: true },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: () => {
            return { name: "admin" };
        },
    },
];
